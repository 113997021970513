<template>
  <div class="container-pc">
    <el-carousel :interval="5000" arrow="always" height="auto">
      <el-carousel-item  v-for="(item, index) in props.data" :key="index">
        <el-image class="swiper-pc" :src="item.src" @click="toPage(item.pathtype, item.url)" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router"
const router = useRouter()
const props = defineProps({
  data: {
    type: Array,
  },
});

const toPage = async (type, url) => {
  if (type == 1) {
    router.push({
      name: "List",
      params: {
        id: url,
      },
    })
  }
  if (type == 2) {
    router.push({
      name: "Info",
      params: {
        id: url,
      },
    })
  }
};
</script>

<style lang="scss" scoped>
.swiper-pc {
  width: 100%;
  height: auto;
}

.el-carousel__container,
.el-carousel__item {
  height: auto;
}
</style>