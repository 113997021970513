<template>
  <div class="container-m">
    <van-swipe class="my-swipe" @change="onChange" lazy-render>
      <van-swipe-item class="swipe-item" v-for="(item, index) in data" :key="index">
        <img :src="item.src || item.detailImgpath || item.listImgpath" alt=""
          @click="toPage(item.pathtype, item.url)" />
      </van-swipe-item>
      <template #indicator="{ active, total }" v-if="indicator === true">
        <div class="custom-indicator">{{ active + 1 }}/{{ total }}</div>
      </template>
    </van-swipe>
    <div class="swipe-mini" v-if="indicator === true">
      <div :class="['mini', active === index ? 'select' : '']" v-for="(item, index) in data" :key="index"
        @click="onChange(index)">
        <img :src="item.src || item.detailImgpath || item.listImgpath" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router"

export default defineComponent({
  name: "Swipe",
  props: ["data", "indicator"],
  setup() {
    const router = useRouter()
    const active = ref(0);
    const total = ref(0);

    const onChange = (index) => {
      active.value = index;
    };


    const toPage = async (type, url) => {
      if (type == 1) {
        router.push({
          name: "List",
          params: {
            id: url,
          },
        })
      }
      if (type == 2) {
        router.push({
          name: "Info",
          params: {
            id: url,
          },
        })
      }
    };

    return {
      active,
      total,
      onChange,
      toPage
    };
  },
});
</script>

<style lang="scss" scoped>
.my-swipe {
  width: 100%;

  .swipe-item {
    width: 100%;
    height: 100%;
  }
}

.custom-indicator {
  position: absolute;
  right: 0.1rem;
  bottom: 0.1rem;
  padding: 0.1rem 0.2rem;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  border-radius: 0.25rem;
}

.swipe-mini {
  display: flex;
  width: 100%;
  padding: 0.3rem;
  background-color: #fff;

  .mini {
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.2rem;
    border-radius: 0.1rem;
    overflow: hidden;
    border: 1px solid #ccc;
  }

  .select {
    border: 1px solid $globalColor;
  }
}

:deep(.van-swipe__indicator) {
  background-color: #414141;
}
</style>