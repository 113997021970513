import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-734e7ea4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "onClick"]
const _hoisted_2 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwipePc = _resolveComponent("SwipePc")!
  const _component_Swipe = _resolveComponent("Swipe")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.homeData, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "home-item container-pc",
        key: index
      }, [
        (item.modeltype == 1)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(['Global-W',
      item.computed === 'width' || item.computed === 'all'
        ? 'item-box-other'
        : 'item-box']
      )
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.childrens, (img, j) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: 'img' + j
                }, [
                  _createElementVNode("img", {
                    src: img.data[0].src,
                    style: _normalizeStyle({
          width: img.data[0].width > 0 ? img.data[0].width + 'px' : 'auto',
        }),
                    onClick: ($event: any) => (_ctx.toPage(img.data[0].pathtype, img.data[0].url)),
                    class: "CURSOR"
                  }, null, 12, _hoisted_1)
                ]))
              }), 128))
            ], 2))
          : _createCommentVNode("", true),
        (item.modeltype === 2)
          ? (_openBlock(), _createBlock(_component_SwipePc, {
              key: 1,
              data: item.childrens[0].data
            }, null, 8, ["data"]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.homeDataM, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "home-item container-m",
        key: index
      }, [
        (item.modeltype == 1)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(item.computed === 'width' || item.computed === 'all'
      ? 'item-box-other'
      : 'item-box'
      )
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.childrens, (img, j) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "Global-W",
                  key: 'img' + j
                }, [
                  _createElementVNode("img", {
                    src: img.data[0].src,
                    style: _normalizeStyle({
          height: img.data[0].height > 0 ? img.data[0].height + 'rem' : 'auto',
          width: img.data[0].width > 0 ? img.data[0].width + 'rem' : 'auto',
        }),
                    onClick: ($event: any) => (_ctx.toPage(img.data[0].pathtype, img.data[0].url))
                  }, null, 12, _hoisted_2)
                ]))
              }), 128))
            ], 2))
          : _createCommentVNode("", true),
        (item.modeltype === 2)
          ? (_openBlock(), _createBlock(_component_Swipe, {
              key: 1,
              data: item.childrens[0].data
            }, null, 8, ["data"]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ], 64))
}