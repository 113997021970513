
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { Swipe, SwipePc } from "../../components/homes";
import requestAsync from "../../utils/request";
import { useStore } from "vuex";
import { useRouter } from "vue-router"
export default defineComponent({
  name: "Home",
  components: { Swipe, SwipePc },
  setup() {
    const router = useRouter()
    const store = useStore();
    const data = reactive<any>({
      homeData: [],
      homeDataM: [],
    });
    /* ------------------------------- methods ------------------------------- */
    onMounted(() => {
      // console.log("onMounted");
      getHomeData();
      // store.dispatch("asyncGetMyCartData");
    });

    const getHomeData = async () => {
      const siteCode = localStorage.getItem('siteCode')

      const homeData: any = await requestAsync({
        baseIp: window.location.origin,
        url: '/' + siteCode + "/p.json",
      });
      const homeDataM: any = await requestAsync({
        baseIp: window.location.origin,
        url: '/' + siteCode + "/m.json",
      });
      data.homeData = homeData.data;
      data.homeDataM = homeDataM.data;
    };
    const toPage = async (type: any, url: any) => {
      console.log(type, url);
      if (type == 1) {
        router.push({
          name: "List",
          params: {
            id: url,
          },
        })
      }
      if (type == 2) {
        router.push({
          name: "Info",
          params: {
            id: url,
          },
        })
      }
    };


    return {
      ...toRefs(data),
      toPage,
    };
  },
});
