<template>
  <div class="container">
    <div class="detail-img" v-for="(item, index) in data" :key="index">
      <img :src="item.detailImgpath" alt="" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "DetailsImg",
  props: ["data"],
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.container {
  padding: .2rem 0.2rem 1rem;
  .detail-img {
    width: 100%;
  }
}
</style>